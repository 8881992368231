@import "./prime-radio";
@import "./prime-tabs";
@import "./prime-dialog";
@import "./prime-toggle-buttons";
@import "./prime-sidebar";
@import "./prime-tree-table";

body {

    .p-tabview,
    .p-component {
        font-family: 'Open Sans' !important;
    }

    .p-field-checkbox label {
        margin: 2px 0 0 10px !important;
        font-family: 'Open Sans';
        color: #000 !important;
        font-size: 14px;

        &.bold-label {
            font-weight: 600 !important
        }
    }

    .p-scrolltop.p-link.p-component {
        bottom: 10vh;
        padding: 0 !important;
        height: 2.25em !important;
        min-width: 158px !important;
        right: 2vw;
        border-radius: 200px;

        &:before {
            content: 'Back To Top';
            color: #fff;
            margin: 1em;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 600;
        }

        .p-scrolltop-icon {
            margin-right: .25em;
            font-size: 1em !important;
        }
    }
}

.force-tt {
    z-index: 1500 !important;
}

.topic-move-list {
    min-height: 40px;
    max-height: 150px;
    overflow-y: scroll;
    margin-bottom: .75em;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
        width: .75em;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}

@media only screen and (max-width:958px) {
    .p-scrolltop.p-link.p-component {
        display: none !important;
    }
}

@media only screen and (max-width: 678px) {

    body .p-dialog:not(.p-confirm-dialog) {
        width: 100vw !important;
        max-height: 100% !important;
        height: 100% !important;
    }

    body .p-confirm-dialog {
        width: 70vw !important;
        height: auto !important;
    }
}