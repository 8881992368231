body .p-sidebar-bottom {
    height: 70vh;
}

.p-sidebar {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: .5em;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}


