#search-panel {
    .p-tabview {
        .p-tabview-nav {
            border: none;

            li .p-tabview-nav-link {
                border: solid transparent;
                border-width: 0 0 2px 0;
                color: #6c757d;
                padding: 1rem;
                font-weight: 600;
                border-top-right-radius: 3px;
                border-top-left-radius: 3px;
                transition: box-shadow 0.2s;
                margin: 0 0 -2px 0;
            }

            li.p-highlight .p-tabview-nav-link {
                border-color: $link-color;
                color: $link-color;
            }
        }

        .p-tabview-panels {
            padding: 0;
        }
    }
}