.top-row .row-title .btn.btn-sm {
  font-size: 14px;
  font-weight: 600;
}

.row-title .btn.btn-sm {
  font-size: 13px;
  font-weight: 500;
}

.respond .sm-visible, .respond .md-visible {
  display: none;
}

.row-title .text-left{
  min-width:175px
}

.leaf td:first-of-type {
  border-left: solid 3px #ccc !important;
  background: linear-gradient(90deg, rgba(236, 236, 236, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.leaf-with-subs td:first-of-type {
  border-left: solid 3px #ccc !important;
}

.p-inputswitch {
  .p-inputswitch-slider {
    border-radius: 30px !important;

    &:before {
      border-radius: 50% !important;
    }
  }

  &.p-inputswitch-checked .p-inputswitch-slider {
    background: $primary;
  }

  &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: darken($primary, 5%);
  }

}

.p-treetable-tbody tr:first-of-type .action-div {
  border-top: 0 !important;
}

body .p-treetable-toggler {
  box-shadow: none !important
}

.p-treetable-toggler {
  text-align: center !important;
  border: solid 1px #444 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.09), 0 1px 3px rgba(0, 0, 0, 0.08) !important;

  &:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.10), 0 3px 6px rgba(0, 0, 0, 0.08) !important;
    transform: translateY(-1px);
    background-color: #efefef !important;
    border-color: #000 !important;
  }

  i {
    color: #444 !important;

    &:hover {
      color: #000 !important;
    }
  }

  border: solid 1px #444 !important;
}


.p-treetable-tbody .btn.btn-sm.btn-link.m-0.p-0.btn-normal.text-left{
  box-shadow:none!important
}

@media only screen and (max-width: 700px) {
  .leaf .text-left {
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (min-width:950px) {
  .respond .row-title .p-treetable-toggler {
    visibility: hidden!important;
  }
}
@media screen and (max-width: 1050px) {
  .respond .md-invisible {
    display: none !important;
  }
  .respond .md-visible {
    display: inline;
    margin-right: .5rem;
  }

}
@media screen and (max-width: 950px) {
  .respond .sm-invisible {
    display: none !important;
  }

  .respond .row-title .p-treetable-toggler {
    visibility: inherit;
  }

  // .respond .leaf .p-treetable-toggler{
  //   display:none;
  // }

  .respond .sm-visible {
    display: inline;
    margin-right: .5rem;
  }

  .leaf td.row-title {
    border-left: solid 3px #ccc !important;
    background: linear-gradient(90deg, rgba(236, 236, 236, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
}