.btn.cta-interact.btn-sm .small {
  display: none;
}

body .about-screen div.w-50{
  margin-right:10px;
  width:48% !important;
}

@media only screen and (max-width:670px){
  body .about-screen div.w-50{
    width:100%!important;
     margin:0; 
  }
}

@media only screen and (max-width: 830px) {
  .btn.cta-interact.btn-sm {
    border: none !important;

    .fa-stack-2x {
      position: relative;
      top: -4px;
    }

    .text-left {
      display: none;
    }

    i {
      padding: .15em !important;
      margin: 0 !important;
    }

    .small {
      display: block;
    }
  }



  .topic-filter.w-50 {
    width: 80% !important;
  }
}

@media only screen and (max-width: 768px) {
  .filter-container {
    flex-wrap: wrap !important;
  }

  .cta-container {
    margin: 1em 0;
  }

  .feature-container {
    flex: 0 0 100%;
  }
}