.role-btn-group {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.175) 3.5%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.175) 3.5%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.175) 3.5%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
    border-radius: 3px;
  
    .btn {
      font-size: 12px;
      text-align: left;
      background-color: #fff;
  
      &:hover {
        box-shadow: none;
        transform: translateX(5px);
      }
    }
  }