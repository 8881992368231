@import "./animated";

p {
    font-weight: 500 !important;
    color: $default-font;
    font-size: 15px !important;
}

.text-normal {
    color: $default-font;
}

.smaller {
    font-size: .8em;
    font-style: oblique;
    font-weight: 600;
    letter-spacing: .05rem;
    padding: .5em 1em .5em .5em;
    border-left: solid 2px #ececec;
    background-color: #f7f7f7
}

.dl-tips {
    font-size: .85em;
    background-color: rgba(212, 212, 222, .11);
    padding: 1em;
    border: solid 1px #ccc;
    border-radius: 10px;
    margin: 1em
}

.title {
    font-weight: 700;
    font-size: 18px;
    color: $default-font
}

.p-tooltip-text {
    font-size: .75em
}

hr {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
}

h3.sb-bold-title {
    font-weight: 700;
    font-size: 18px;
}

h4.page-heading {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: -10px;
    margin-top: 10px
}

.normalize {
    text-transform: none !important;
}

.medium {
    font-weight: 600
}

.bold {
    font-weight: 700;
}

.external-notice {
    border-left: solid 3px #fb6340;
    background-color: #fff;

    h4.title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0;
        margin-top: 10px;
    }

    p {
        font-size: 13px !important;

        &.emphasis {
            font-weight: 600 !important;
            font-style: oblique;
            letter-spacing: .025em;
            margin-top: -.75em;
        }
    }
}

/* CTA Interact Buttons */
.cta-title {
    font-size: 14px;
    font-weight: 700;
}

.cta-subtitle {
    text-transform: none;
    font-size: 12px;
    font-weight: 100;
}

/* Sidebar */
.sb-title {
    margin-bottom: 1em;
    color: $link-color;
    font-size: 16px;
}

.return-link {
    font-size: 14px;
    font-weight: 700;
}


.fs-115 {
    font-size: 1.15em
}

.safe-secure {
    border-left: solid 2px;
    padding: 0 1em;
    margin: 1em 0;

    p {
        font-size: 14px !important;
        font-weight: 400 !important;
    }
}

.data-map h5 {
    font-size: .85em !important;
    font-weight: 600 !important;
}

.data-map-title {
    text-align: center;
    font-size: 1.25em;
    font-weight: 400;
    padding: .5em 0
}

.filter-header {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

.featured-tag {
    background: rgb(255, 255, 255);
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.09287464985994398) 0%, rgba(20, 106, 96, 0.04245448179271705) 100%);
    border-radius: 3px;
    padding-left: 10px;
    margin-left: -5px;
    color: $success;
    cursor: default;
}

.fa-stack {
    font-size: 14px
}

.text-date {
    font-family: monospace;
    color: #8898aa;
    font-size: 14px;
}

.topic-tags{
    font-size:14px;
    color:#777;
    letter-spacing:0.02rem;
    cursor:default
}

.topic-tags i{
    cursor:help
}

.alert-edge{
    background-color:#fff;
    border-left:solid 3px #fb6340;
    color:#fb6340;
    padding:1em;
    display:block;
    margin-bottom:.5em;
}

.form-control{
    color: $black;
}

body input::placeholder{
    color: $black !important
}

.text-increase{
    font-size:1.5em
}