.p-togglebutton.p-button.tb-dark {
  background-color: rgba($gray, .15) !important;
  color: $dark !important;
  border: 1px solid transparent !important;
  padding: 3.85px 11px !important;
  margin: 5px;

  &:hover {
    background-color: rgba($dark, .05) !important;
    color: $dark;
    border: 1px solid $dark !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }

  .p-button-label {
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 600;
    padding: 0;
  }

  .p-button-icon {
    color: lighten($dark, 15%) !important;
    font-size: 11px;
    padding: 0;
  }

  &.p-highlight {
    background: $dark !important;
    border-color: $dark !important;
    color: #ffffff !important;

    .p-button-icon {
      color: #fff !important;
    }
  }
}
.p-togglebutton.p-button.tb-primary {
  background-color: rgba($primary, .15) !important;
  color: $primary !important;
  border: 1px solid transparent !important;
  padding: 3.85px 11px !important;
  margin: 5px;

  &:hover {
    background-color: rgba($primary, .05) !important;
    color: $primary;
    border: 1px solid $primary !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }

  .p-button-label {
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 600;
    padding: 0;
  }

  .p-button-icon {
    color: lighten($primary, 15%) !important;
    font-size: 11px;
    padding: 0;
  }

  &.p-highlight {
    background: $primary !important;
    border-color: $primary !important;
    color: #ffffff !important;

    .p-button-icon {
      color: #fff !important;
    }
  }
}

.p-togglebutton.p-button.tb-yellow {
  background-color: rgba($yellow, .15) !important;
  color: darken($yellow, 15%) !important;
  border: 1px solid transparent !important;
  padding: 3.85px 11px !important;
  margin: 5px;

  &:hover {
    background-color: rgba($yellow, .05) !important;
    color: $black;
    border: 1px solid $yellow !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }

  .p-button-label {
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 600;
    padding: 0;
  }

  .p-button-icon {
    color: darken($yellow, 15%) !important;
    font-size: 11px;
    padding: 0;
  }

  &.p-highlight {
    background: $yellow !important;
    border-color: $yellow !important;
    color: #ffffff !important;

    .p-button-icon {
      color: #fff !important;
    }
  }
}

.p-togglebutton.p-button.tb-success {
  background-color: rgba($success, .15) !important;
  color: $success !important;
  border: 1px solid transparent !important;
  padding: 3.85px 11px !important;
  margin: 5px;

  &:hover {
    background-color: rgba($success, .05) !important;
    color: $success;
    border: 1px solid $success !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }

  .p-button-label {
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 600;
    padding: 0;
  }

  .p-button-icon {
    color: lighten($success, 15%) !important;
    font-size: 11px;
    padding: 0;
  }

  &.p-highlight {
    background: $success !important;
    border-color: $success !important;
    color: #ffffff !important;

    .p-button-icon {
      color: #fff !important;
    }
  }
}


body .p-togglebutton.p-button.tb-lg {
  padding: 1em 1.25em !important;

  .p-button-label, .p-button-icon {
    font-size: 12px;
  }
}