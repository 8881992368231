
@media only screen and (max-width: 992px) {
  .p-dialog {
    width: 65vw !important;
  }
}

@media only screen and (max-width: 678px) {
  .p-dialog {
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
    transform: none;
    width: 98vw !important;
    max-height: 100% !important;
    height: 98% !important;

    .p-dialog-content {
      flex-grow: 1;
    }
  }
}

body.p-overflow-hidden #beacon-container,
body.admin-active #beacon-container{
  display: none
}

.sticky-top{
  z-index: 999 !important;
}