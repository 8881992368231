.is-admin {
  background-color: #efefef;

  .background-image {
    background-image: none;
    background-color: #efefef;
  }

  .admin-content-items {
    border-left: solid 2px transparent !important;

    &.in-use {
      border-left-color: $success !important;
    }
  }

  .opaque {
    display: none;
  }

  .container.main {
    max-width: 85% !important;
  }

  .p-tabview {
    padding: 1.5em 1em;
  }

  .text-success .admin-list-title {
    font-weight: 700 !important;
    color: $success;
  }

  .text-muted .admin-list-title {
    font-weight: 100 !important;
    color: darken($muted, 20%);
  }

  .text-date {
    font-weight: normal !important;
    font-size: 12px !important;
  }

  .clip-url {
    max-width: 40vw !important;
  }


}

.row-deleted {
  background-color: #efefef !important;

  .topic-cell {
    display: inline-block;
  }

  .line-through {
    font-size: 13px;
    font-weight: 600;
    text-decoration: line-through;
  }

  .topic-admin-title {
    font-weight: 700 !important
  }

  .featured-section {
    padding: 0.25rem 0.5rem;
  }
}

.p-confirm-dialog {
  // .p-dialog-header {
  //   display: none;
  // }

  // .p-dialog-content {
  //   border-top-left-radius: 4px;
  //   border-top-right-radius: 4px;
  // }

  .p-dialog-footer {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row-reverse !important;

    .p-confirm-dialog-accept {
      background-color: $primary;
      border-color: $primary;

      &:hover {
        color: #fff;
        background-color: #002743!important;
        border-color: #001f36!important;
      }
    }

  .p-confirm-dialog-reject {
    color: $dark;
    background-color: $secondary;

    &:hover {
      background-color: #dee1e7;
      border-color: #d6dae2;
      color: $dark;
    }
  }

  .p-button {
    font-size: 14px;
    padding: 0.5em 1.25em;
  }
}
}