@import "./table-of-contents";

.shadow-sm.shadow-dark {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25) !important;
}

.bread-bg {
    background-color: #F4F6F6;
    padding: .25em .5em;
}

.suggested-topic {
    border-top: solid 2px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    .btn:not(.reset-search):hover {
        box-shadow: 0 4px 2px -2px rgba(105, 105, 153, 0.05), 0 4px 2px -2px rgba(0, 0, 0, 0.05);
        color: #000;
        border-bottom: solid 1px $purple !important;
        border-radius: 0;
    }
}

.no-results {
    border-top-color: $warning !important
}

.topic-title {
    font-size: 17px;
    font-weight: 600 !important;
    margin-bottom: 0;
    color: $purple;
}

.subtopic-title {
    font-size: 14px;
    font-weight: 600 !important;
    color: $purple;
}



.suggested-topic .btn small {
    font-size: 12px;
    font-weight: 600 !important;
}

.breadcrumb {
    background-color: #f4f5f7 !important;
    max-width: 55vw
}

.breadcrumb-item {
    font-size: .85em;
    color: $link-color;
    cursor: pointer;
    text-transform: capitalize;

    &:last-of-type {
        font-weight: 700
    }
}

.no-results {
    h4 {
        margin-top: .5em;
        color: #000;
    }

    dl {
        color: #000;
        margin: 1em .25em;

        dt {
            margin-bottom: .5em;
        }

        dd {
            margin-left: 1em;
            font-size: .975em;

            &:before {
                content: '-';
                margin-right: .5em;
                color: #666;
            }
        }
    }
}

.topic-description{
    color:darken($purple, 15%);
    font-size:13.5px;
    margin:.5em 0 1em 0;
}

.btn.btn-normal {
    text-transform: none !important;
    font-weight: 900 !important;
}

@media only screen and (max-width:740px) {
    .flex-custom-wrap {
        flex-wrap: wrap !important;
    }
}