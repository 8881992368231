@import "./topic-filter";
@import "./interact-resource";

.form-group label {
  font-size: 16px;
  font-weight: 600!important;
}


.input-sublabel {
  border-left: solid 2px;
  padding-left: .5em;
  color: $danger;

  &:before {
    content: '\f0da';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 700;
    margin-left: -.5em;
    padding-right: 0.5em;
  }
}

.req-marker{
  font-size:.5em;
  position:relative;
  top:-10px;
  color:$danger
}