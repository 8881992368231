.toc {
    min-width: 20vw;
    margin-right: -25px;
    margin-left: 10px;
    background-color: #fff;
    padding: 1em;

    dl {
        max-height: 65vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top:.25em!important;
        padding:.25em 0;

    }

    dd {
        font-size: 13px;
        font-weight: 600;
        text-transform: capitalize;
        color:$purple;
        padding:0 .5em 0 0!important;
    }
    dd:hover{
        color:darken($purple, 35%)
    }

    .toc-title {
        font-weight: 600;
        margin-bottom:.5em;
        font-size:1rem!important;
        color:#404040
    }
    .toc-icon{
        font-size:1.5em;
        color:#404040
    }

}





.toc dl{
    &::-webkit-scrollbar {
        width: .35em;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}

@media only screen and (max-width:958px){
    .d-toc-none, .toggle-toc{
        display:none!important;
    }
}