.s-heading {
    font-weight: 600;
  }
  
  .s-snippet {
    background-color: #f4f5f7;
    border-radius: 5px;
    padding: .5em 1em;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  
    p {
      margin: 0;
      padding: 0;
    }
  }
  
  .s-url {
    margin-top: 1em;
    font-size: .8em;
    letter-spacing: .05rem;
    margin-left: 1em;
  }

  .clip-url{
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
  }
  
  .s-result {
    border: solid 1px transparent;
  
    b {
      font-weight: 700;
      background-color: rgba($purple, .10);
      //color: $purple;
      padding: 0 .15em;
      margin:0;
      //margin: 0 .15em;
      border-radius: 2px;
  }
  
    &:hover {
      background-color: rgba(244, 245, 247, 0.7) !important;
      border: solid 1px #d2d2d2;
  
      .s-snippet {
        background-color: transparent !important;
        border-radius: 0;
        box-shadow: none !important;

        b {
            font-weight: 700;
            background-color: rgba($purple, .10);
            //color: $purple;
            padding: 0 .15em;
            margin:0;
            //margin: 0 .15em;
            border-radius: 2px;
        }
      }
    }
  }