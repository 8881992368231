.background-image {
    height: 100vh;
    background-position: top;
    background-repeat: no-repeat;
    background-color:#fff;
    background-image: none;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
}

.image-1 {
    background-image: url('./../images/blended-1.jpg');
}

.image-2 {
    background-image: url('./../images/blended-2.jpg');
}

.image-3 {
    background-image: url('./../images/blended-3.jpg');
}

.image-4 {
    background-image: url('./../images/blended-4.jpg');
}

.image-5 {
    background-image: url('./../images/blended-5.jpg');
}

.image-6 {
    background-image: url('./../images/blended-6.jpg');
}

.image-7 {
    background-image: url('./../images/blended-7.jpg');
}

.opaque {
    height: 55vh;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80ffffff', endColorstr='#ffffff', GradientType=0);
    position: absolute;
    top: 45vh;
    z-index: -1;
    width: 100%;

}

@media (max-width: 1995px) {
    .background-image {
        background-size: cover;
    }
}

@media (max-width: 767px) {
    .background-image {
        background-image: none !important;
        background: -moz-linear-gradient(top, rgba(229, 229, 229, 0.65) 0%, rgba(255, 255, 255, 1) 100%) !important;
        background: -webkit-linear-gradient(top, rgba(229, 229, 229, 0.65) 0%, rgba(255, 255, 255, 1) 100%) !important;
        background: linear-gradient(to bottom, rgba(229, 229, 229, 0.65) 0%, rgba(255, 255, 255, 1) 100%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6e5e5e5', endColorstr='#ffffff', GradientType=0);
        height: 20vh !important
    }
}
