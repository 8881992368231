.dropdown:not(.result-sort) {
    .dropdown-menu{
        min-width:25vw;
        width:100%;
    }
    
    .dropdown-toggle {
        padding: 5px 10px !important;
        margin: 1em .5em;
        background-color: #fff;
        box-shadow: none;
        border: none;
        color: $warning;
        font-size: 16px;

        &:after {
            position: relative;
            top: 2px;
            margin-left: .5em;
            font-size: 1.25em;
        }

        &:hover {
            transform: none !important;
            color: $primary;
        }
    }

    .dropdown-item {
        padding: 5px 10px !important;
    }
}

.result-sort {
    .dropdown-toggle {
        margin: 0.5em !important;
        padding: 5px 10px !important;
        text-transform: none;
        font-size: 12px;
        font-weight: bold;
        box-shadow: none;
        border-radius: 2px !important;

        &:hover {
            box-shadow: none;
        }

        &:after {
            position: relative;
            top: 2px;
            margin-left: .5em;
            font-size: 1em;
            color: rgba(68, 68, 68, 0.65);
        }
    }

    .dropdown-item {
        padding: 5px 10px !important;
        font-size: 12px !important;
    }
}


.dropdown-item.active, .dropdown-item:active {
    color: $primary !important;
    text-decoration: none;
    background-color: #fff !important;
    border:none !important
}

.dropdown-item:hover, .dropdown-item:focus {
    outline: none !important;
}