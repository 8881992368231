.p-radiobutton {
    .p-radiobutton-box .p-radiobutton-icon {
      width: 8px !important;
      height: 8px !important;
    }
  
    &.p-component {
      + label {
        cursor: pointer;
      }
  
      &.p-radiobutton-checked + label {
        font-weight: 700;
      }
    }
  
    .p-radiobutton-box.p-highlight {
      border-color: $link-color !important;
      background: $link-color !important;
    }
  }

  .radio-orange .p-radiobutton {
    .p-radiobutton-box.p-highlight {
      border-color: $warning !important;
      background: $warning !important;
    }
  
    &.p-component.p-radiobutton-checked + label {
      color: $warning;
    }
  }