.no-shadow {
    box-shadow: none !important;
}


body .nav-item small {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: -.05rem;
    color: #777777;
}


.sb-mini {
    display: none;
    padding: 0;

    .nav-pills-circle.nav.nav-pills {
        padding-top: 1em;
    }
}

.rounded-circle.options-btn.nav-link {
    border: solid 1px transparent;
    background-color: $warning;

    &.pulse {
        animation: 2s infinite pulse;
    }

    &:hover {
        background-color: #fff !important;
        color: $warning !important;
        border: solid 1px $warning;
        animation: none;
    }
}

.rounded-circle.menu-btn.nav-link {
    border: solid 1px $secondary;

    &.untouched {
        border: solid 1px $dark;
    }

    &:hover {
        background-color: #fff !important;
        color: $primary !important;
        border: solid 1px $primary;
        animation: none;
    }
}

.p-sidebar-icons.sticky-top {
    background-color: #fff !important;
    top: -20px;
    margin-left: -15px;
    margin-right: -15px;
}

.sb-header.sticky-top {
    background-color: #fff;
    padding: 1em;
    top: -30px;
    margin-top: -30px;
    margin-bottom: -30px;
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.05), 0 3px 6px rgba(0, 0, 0, 0.02);
}

.nav-pills-circle .nav-link-icon i.lg {
    font-size: 1.7em
}

@media only screen and (min-width:958px) {
    .d-sp-none {
        display: none !important;
    }
}

@media only screen and (max-width: 958px) {
    .sb-main {
        display: none;
    }



    .sb-mini {
        display: block;
        max-width: 10% !important;
        flex: 0 0 100% !important;
        // margin-left: -15px;
    }

    .topics-main {
        max-width: 90% !important;
        flex: 0 0 100% !important;
    }
}


@media only screen and (max-width: 730px) {
    .nav-pills-circle .nav-link {
        height: 50px !important;
        width: 50px !important;
        line-height: 50px !important;
    }

    .sb-mini {
        display: block;
        max-width: 12% !important;
        flex: 0 0 100% !important;
        padding-left: .4em;
        // margin-left: -15px;
    }

    .topics-main {
        max-width: 88% !important;
        flex: 0 0 100% !important;
    }
}

@media only screen and (max-width: 500px) {
    .nav-pills-circle .nav-link {
        height: 40px !important;
        width: 40px !important;
        line-height: 40px !important;
    }

    .sb-mini {
        display: block;
        max-width: 15% !important;
        flex: 0 0 100% !important;
        padding-left: .75em;
        // margin-left: -15px;
    }

    .topics-main {
        max-width: 85% !important;
        flex: 0 0 100% !important;
    }

    body .nav-item small {
        font-size: 12px;
    }
}


@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.25);
    }

    50% {
        box-shadow: 0 0 0 6px rgba(251, 99, 64, 0.3);
    }

    100% {
        box-shadow: 0 0 0 3px rgba(251, 99, 64, 0.15);
    }
}

@keyframes pulse-dark {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
    }

    50% {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
    }

    100% {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
    }
}