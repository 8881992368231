.gsc-control-cse {
    width: auto;
    font-family: 'Open Sans' !important;
    font-size: 1em !important;
    font-weight: 500 !important;

    .gsc-table-result {
        width: auto;
        font-family: 'Open Sans' !important;
        font-size: 1em !important;
        font-weight: 500 !important;
    }

    b,
    .gsc-table-result b {
        font-weight: 700;
        background-color: rgba($purple, .10);
        //color: $purple;
        padding: 0 .2em;
        margin: 0 .15em;
        border-radius: 2px;
    }

    .s-heading {
        font-size: 16px !important;
    }

    .s-snippet {
        color: $default-font;
        font-size: 15px !important;
    }

    .s-url {
        font-size: 12.8px !important;

        .text-muted {
            font-size: 12.8px !important;
            float: right;
        }
    }
}

.gsc-tabHeader.gsc-inline-block {
    font-size: 16px;
    color: $default-font;
    margin: 0 1em;

    &.gsc-tabhActive {
        font-size: 16px;
        color: $link-color;
        border-bottom-color: $link-color;
        border-bottom-width: 3px;
        padding-bottom: 8px;
    }
}

.gsc-result-info,
.gcsc-branding,
.gsc-thumbnail,
.gs-snippet,
.gsc-url-top,
.gsc-url-bottom,
.gsc-result-info-container {
    display: none;
}

.gcsc-find-more-on-google {
    display: none !important
}

.gcsc-branding-clickable {
    pointer-events: none;
}

.ext-link {
    font-size: 13px;
    border-top: solid 1px $secondary;
    border-left: solid 2px darken($secondary, 50%);
}

.gsc-cursor-page {
    &:first-of-type {
        border-right: 0;
    }

    font-size: 16px;
    padding: .5em 1em;
    border: 1px solid $secondary;
    border-right: 0;
    margin: 0 !important;
}

.gsc-results .gsc-cursor-box .gsc-cursor-page:hover {
    text-decoration: none !important;
    background-color: $secondary;
    color: $black;
    transform: translateX(5px) !important;
}

.gsc-cursor-page:last-of-type {
    border-right: 1px solid $secondary;
}

.gsc-selected-option,
.gsc-option {
    font-size: 14px;
}

.gsc-selected-option-container.gsc-inline-block {
    cursor: pointer;
}

.gsc-selected-option-container {
    border: none !important;

}

.gsc-orderby-label.gsc-inline-block {
    font-size: 12.8px;
    font-weight: 400;
}

.gsc-selected-option {
    font-size: 12px !important
}