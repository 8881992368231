// Override bootstrap variables

// See node_modules/bootstrap/scss/_variables.scss for a list of variables that can be updated

$primary: #003d69 !default;
$warning: #e02f07 !default;
$yellow: #d68f1b !default;
//fb6340
$link-color: #005DA5;
$blue: $link-color;
$link-hover: darken($link-color, 50%);
$danger: #B83835 !default;
$success: #146A60 !default;
$hover: #F9F9F9;
$gray: #cccccc;
$default-font: #404040 !default;
$headings-color:$default-font;
$black: #000000 !default;
$purple: #32325D !default;
$muted: #8898aa !default;

body {
    color: $default-font;
}