.modal-form .form-group:not(.has-subs):focus-within {

  input,
  .form-control {
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: $link-color;
    color: $black !important;
  }
}

body {
  .p-dropdown-label.p-inputtext {
    font-family: 'Open Sans';
    color: #000 !important;
    font-size: 14px;
  }

  .p-dropdown-item {
    font-family: 'Open Sans';
    font-size: 14px;
  }
}