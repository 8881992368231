.p-tabview .p-tabview-nav {
    border: 0 !important;
  
    li {
      &.p-highlight .p-tabview-nav-link, &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link, .p-tabview-nav-link {
        border: 0 !important;
      }
    }
  }
  
  .p-tabview-selected .p-tabview-title {
    font-weight: 700;
    color: $link-color;
    border-bottom: solid 3px;
    padding-bottom: 8px;
  }
  
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    &:not(.p-disabled):focus {
      box-shadow: none !important;
    }
  
    color: $default-font !important;
  
    &:hover {
      color: $black !important;
  
      .p-tabview-title {
        border-bottom: solid 3px;
        padding-bottom: 8px;
      }
    }
  }