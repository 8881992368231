
.clearable:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.action-search {
    padding: 10px 15px 10px 10px;
    cursor: pointer;
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;

    &:hover {
        i {
            font-weight: 700;
            color: $primary !important;
        }

    }
}

.action-clear {
    padding: 10px 20px 10px 10px;
    cursor: pointer;

    &:hover {
        background-color: lighten($primary, 5%);
        color: #fff;
        border-bottom-right-radius: 50rem !important;
        border-top-right-radius: 50rem !important;
    }
}

.filter-pills {
    font-size: 11px;
    font-weight: 600;
  
    .rounded-pill {
      text-transform: uppercase;
      margin-top: 3px;
  
      span {
        padding: 0.35em 1em;
      }
  
      &.active {
        background-color: $primary !important;
        color: #fff !important;
  
        .action-clear {
          border-left: solid 1px #000;
        }
      }
    }
  }