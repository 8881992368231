@import "./pills";

.btn {
    &.no-shadow {
        box-shadow: none;
    }

    &.text-normal {
        text-transform: none;
    }

    :disabled {
        cursor: not-allowed;
        opacity: .35 !important
    }
}

.cursor-pointer {
    cursor: pointer;
}

.btn-cta {
    padding: .5em 1em !important;

    &:hover{
        color: #fff !important;
        i{
            color: #fff !important;
        }
    }
}

.btn.sb-btn {
    text-align: left;
    padding: 1em;
    margin-bottom: .5em;
    box-shadow: none;
    text-transform: none;
    color: $link-color;
    font-weight: 500;
    font-size: 13px;

    &:hover {
        box-shadow: none;
        transform: none;
    }
}

.btn.btn-term-link {
    margin: .25em 0;
    padding: 0 0.15em !important;
    text-transform: none !important;
    font-weight: normal !important;
    border-bottom: solid 1px $link-color !important;
    border-radius: 0;
    color: $link-color;

    &:hover {
        background-color: #efefef;
        border-bottom-color: transparent !important;
        color: darken($link-color, 20%);
    }
}

.featured-pills {
    .btn {
        font-size: 12px;
        padding: .6em 1em;
    }

    .btn-md {
        font-size: 11.5px;
        padding: .4em 1.1em;
    }

    .btn-sm {
        font-size: 11px;
        padding: .4em 1.1em;
    }
}

.cta-interact {
    border: solid 1px rgba(50, 50, 93, 0.3) !important;
    color: $purple;

    &:hover {
        border: solid 1px $purple !important;
    }
}

@each $color,
$value in $theme-colors {
    .btn-#{$color}-lighten {
        background-color: rgba($value, .15);
        color: darken($value, 5.75%);
        border: 1px solid transparent;
        text-transform: none;
    }

    .btn-#{$color}-lighten:hover {
        background-color: rgba($value, .05);
        color: $value;
        border: 1px solid $value;
    }

    .btn-outline-#{$color}.current {
        color: $value
    }

    .btn-outline-#{$color}:hover.current {
        background-color: #fff;
    }
}


.btn-similar {
    text-transform:none !important;

    i {
        font-size: 18px;
    }

    &:hover {
        background-color: #fff;
        border-bottom: solid 1px;
        border-radius: 0 !important;
    }
}

.btn-primary{
    &:hover{
        color:#fff
    }
}

.btn.disabled:hover, .btn:disabled:hover {
    box-shadow:none!important;
    transform:none;
    cursor:not-allowed;
}