.site-notice {
    font-size: 1.15rem;
    font-weight: 600;
    padding: .25em;
    background-color: transparent !important;
}

.close-site-notice {
    font-size: 20px !important;
    margin: (-1em) 0.5em;
}

.push-page-element {
    height: 84px;
    background-color: transparent;
    animation: slide-in 0.35s;
}

.site-notice-wrapper {
    color: #fff;
    background-color: #146A60;
    box-shadow: 0 3px 13px 0 rgba(54, 61, 74, 0.31);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    animation: slide-in 0.35s;

    .d-flex.justify-content-between {
        animation: fade-in 1.25s forwards;
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes slide-in {
    0% {
        height: 0;
        opacity: 0;
    }

    50% {
        height: 50px;
        opacity: 50%;
    }

    100% {
        height: 100px;
        opacity: 100%;
    }
}

@keyframes slide-in {
    0% {
        height: 0;
        opacity: 0;
    }

    50% {
        height: 50px;
        opacity: 50%;
    }

    100% {
        height: 100px;
        opacity: 100%;
    }
}

@media only screen and (max-width:880px) {
    .site-notice {
        font-size: .9rem !important
    }
}


@media only screen and (max-width:692px) {
    .push-page-element, .site-notice-wrapper {
        animation: slide-in 0.15s;
    }

    .site-notice-wrapper .d-flex.justify-content-between{
        animation: fade-in 0.75s forwards;
    }
    .push-page-element {
        height: 144px !important;
        background-color: transparent;
    }
    @-webkit-keyframes slide-in {
        0% {
            height: 0;
            opacity: 0;
        }
    
        50% {
            height: 72px;
            opacity: 50%;
        }
    
        100% {
            height: 145px;
            opacity: 100%;
        }
    }
    
    @keyframes slide-in {
        0% {
            height: 0;
            opacity: 0;
        }
    
        50% {
            height: 72px;
            opacity: 50%;
        }
    
        100% {
            height: 145px;
            opacity: 100%;
        }
    }
}