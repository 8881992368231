.top-search input {
    padding:1.85em 1em;
    border-color: #888;
}

.auto-complete-terms {
    max-height: 10em;
    overflow-y: scroll;
}

.auto-complete-footer {
    padding-top: 0.5em;
    border-top: $gray 1px solid;
    margin: 5px -15px 0 -15px;

    a {
        margin: .2em 1em -.5em 1em;
        font-size: 12px;
        color: $link-color;

        &:hover {
            color: $link-hover;
        }
    }
}

.top-search:focus-within, .has-term {
    .input-group.input-group-alternative {
        // box-shadow: 0 1px 3px rgba(50, 50, 93, .5), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, .50) !important;
        border: solid 1px rgba(50, 50, 93, .5) !important;
    }

    input {
        border-color: $black;
        color: $black;
        font-weight: 700;

        &:not(::placeholder) {
            border-color: $black;
            color: $black;
            font-weight: 700;
        }
    }
}

.has-term{
    .input-group.input-group-alternative {
        box-shadow: 0 1px 3px rgba(50, 50, 93, .2), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
        border: solid 1px rgba(50, 50, 93, .2) !important;
    }
}

input.form-control.has-term {
    border-color: $black;
    color: $black;
    font-weight: 700;
}


.term {
    .ac-term {
        font-size: 14px;
        padding: .25em 1em .25em .05em;
    }

    width: 99%;
    border: solid 1px transparent;

    &:hover {
        background-color: $hover;
    }

    &.hover-search {
        background-color: rgba($success, .15);

        .ac-term i {
            color: $success !important;
        }
    }

    &.hover-clear {
        border: dotted 1px $danger;

        .ac-term {
            text-decoration: line-through;
            color: $danger;
        }
    }

    .ac-term {
        border-left: solid 2px transparent;

        i {
            margin-right: 0.65em;
        }

        &:hover {
            background-color: #fff;
            border-left: solid 2px $success;
            color: $success;
        }
    }
}

/* Scroll */
.auto-complete-terms {
    &::-webkit-scrollbar {
        width: .75em;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}