

.topic-filter.form-group:focus-within {
    .input-group.input-group-alternative {
        //box-shadow: 0 1px 3px rgba(50, 50, 93, .5), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, .50) !important;
        border: solid 1px rgba(50, 50, 93, .5) !important;
    }

    input {
        border-color: $black;
        color: $black;
        font-weight: 700;
    }
}

.input-group-text.selectable {
    background-color: transparent;
    padding-left: 1.25em;
    padding-right: 1.25em;
    cursor: pointer;

    &:hover {
        background-color: #efefef;
        color: #000;
    }
}