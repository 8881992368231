@import "./site-notice";
@import "./topic-cards";
@import "./sb-mini";
@import "./responsive";
@import "./admin";

main {
  overflow: visible!important
}

.img-logo {
    height: 12vh !important;
}

.container.main {
    max-width: 96%
}


.img-footer-logo {
    height: 12vh !important;
    min-height: 90px;
}

.footer.has-cards {
    width: 100% !important;
}

.sb-alert {
    padding: 5px;
    margin-top: 1em;
}

.sb-note-view-all {
    border-left: solid 2px #000;
    padding-left: 10px;
    margin-left: 1em;
    margin-top: -0.5em;

    h4 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
    }
}

@media only screen and (max-width: 768px) {
  .container {
      max-width:98%!important
  }
}

@media only screen and (max-width:830px){
    .w-50.topic-filter-search{
        max-width:100%!important;
        width:100%!important
    }
}